<template>
  <full-view :title="$t('app.route.changePassword')">
    <template v-slot:toolbar>
      <v-btn large depressed color="primary" @click="tryResetPassword">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid">
      <v-card class="ma-5 pa-5" :style="getViewHeightStyle(76)">
        <pin-code-buttons :purpose="getPurpose" />
        <v-text-field
          v-model="model.resetCode"
          :label="$t('user.pinCode')"
          :rules="rules.pinCode"
          clearable
          outlined
          append-icon="mdi-shield-check"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="model.password"
          :label="$t('user.newPassword')"
          :rules="rules.password"
          clearable
          outlined
          :type="showPassword ? '' : 'password'"
          append-icon="mdi-lock"
          validate-on-blur
        ></v-text-field>

        <v-text-field
          v-model="model.confirmPassword"
          :rules="rules.confirmPassword"
          :label="$t('user.confirmPassword')"
          append-icon="mdi-lock-check"
          type="password"
          clearable
          outlined
          validate-on-blur
        ></v-text-field>

        <v-checkbox label="Show Password" v-model="showPassword" />

        <v-divider />

        <v-card-actions>
          <v-btn large depressed color="primary" @click="tryResetPassword">
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </full-view>
</template>

<script>
import { mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import PinCodeButtons from "@/common/components/PinCodeButtons";
import { CODE_RESET_PASSWORD } from "@/store/actions/type.auth";

export default {
  name: "ChangePassword",
  components: { FullView, PinCodeButtons },
  data() {
    return {
      valid: false,
      showPassword: false,
      model: { password: "", confirmPassword: "", resetCode: "" },
      rules: {
        pinCode: [
          (v) => !!v || "Pin-code is required, click on Pin-Code button above and find the code in your email or sms messages",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
        ],
        confirmPassword: [
          (v) => !!v || "Confirm Password is required",
          (v) => v === this.model.password || "Password and confirmation must match",
        ],
      },
    };
  },
  computed: {
    getPurpose() {
      return CODE_RESET_PASSWORD;
    }
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),

    tryResetPassword() {
      this.$refs.form.validate();

      setTimeout(() => {
        if(this.valid) {
          this.resetPassword(this.model).then(() =>
            this.$router.push({
              name: "settings",
              params: { back: { name: this.$route.name } }
            })
          );
        }
      }, 500);
    },
  },
};
</script>
